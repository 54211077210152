.editor .card {
  background-color: rgb(216, 211, 211);
}

.editor .textarea {
  /* width: 100%; */
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.733);
  margin-top: 25px;
  overflow-x: auto !important;

  overflow-wrap: normal;
  white-space: pre;
  width: 100%;
}
/* .editor-lyric .title {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  color: rgb(34, 32, 32);
  font-size: calc(1.325rem + 0.9vw);
  width: 100%;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
} */
.editor .title {
  margin-top: 20px;
}

.editor .key {
  margin-top: 20px;
  width: 50px;
}

.editor .title:focus,
textarea:focus {
  outline: none;
}

.editor .artist {
  margin-top: 20px;
}
