.title {
  color: white;
  margin: 1em;
}

.inputs {
  padding: 1em !important;
}

.text-input {
  max-width: 300px;
}

.button-input {
  max-width: 300px;
}