.content {
  padding: 20px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.song-name {
  /* border-left: 2px solid rgba(133, 133, 133, 0.699); */
  transition: transform 0.2s; /* Animation */
}

.song-name:hover {
  /* transform: scale(1.02); */
  background-color: rgba(152, 176, 228, 0.288) !important;
}

.download {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
}
