.song {
  .card {
    background-color: rgb(216, 211, 211);
  }

  pre {
    display: block;
    font-size: 87.5%;
    color: #000000;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
  }

  .lyric {
    display: block;
    margin: 0;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
    /* font-size: 17.5px; */
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.733);
    border-radius: 5px;
    border: none;
  }

  .btns {
    overflow: auto;
  }
  .btn {
    margin-top: 5px;
    margin-left: 5px;
  }

  .action-btn {
    background-color: rgba(106, 127, 184, 0.884);
    padding: 3px;
    color: white;
    height: 35px;
    width: 40px;
  }
  .action-btn:hover {
    background-color: rgb(106, 127, 184);
    color: rgb(206, 205, 205);
  }

  .icon {
    margin: 0;
    font-size: 22px;
  }

  .the-key {
    background-color: rgba(111, 172, 212, 0.651) !important;
  }

  .header {
    margin-bottom: 10px;
  }

  .custom-title {
    display: inline-block;
    font-size: 25px;
    color: #313131;
  }

  .actions {
    display: block;
    float: right;
  }

  .transpose {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .key {
    min-width: 35px;
    background-color: #eeeeee;
    font-weight: 550;
  }

  .artist {
    margin-top: 20px;
    font-style: italic;
  }

  .chords {
    color: rgb(39, 70, 206);
    font-weight: bold;
  }

  .notes {
    display: block;
    width: 100%;
  }

  .default-key {
    display: inline-block;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: italic;
    font-weight: bold;
    color: black;
    padding: 5px 12px 5px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .song {
    .card-content {
      padding: 5px;
    }

    .lyric {
      font-size: 2.8vw;
      padding: 5px;
    }
  }
  /* .song .title {
    font-size: 5vw;
  } */
}
