$color-text2: rgb(101, 101, 101);
$color-text3: rgb(60, 60, 60);

.song-list {
  .stack-format {
    .item {
      position: relative;
      text-align: left;
      border: solid 1px rgb(182, 182, 182);
      border-radius: 5px;
      margin-bottom: 10px;
      // transition: 0.4s;
      padding: 2px 10px 2px 10px;

      .body {
        display: inline-block;

        .index-num {
          color: $color-text3;
          margin-right: 5px;
        }

        .name {
          display: inline-block;
          text-decoration-line: underline;
          color: rgba(0, 0, 255, 0.76);
          cursor: pointer;
        }

        .artist {
          display: inline-block;
        }

        .category {
          font-size: 13px;
          display: block;
          font-style: italic;
          color: $color-text2;
          float: right;
        }

        .key {
          // font-size: 15px;
          display: block;
          color: $color-text2;
          margin-left: 5px;
          float: right;
        }
      }

      .buttons {
        display: inline-block;
        width: 20%;
        text-align: center;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
