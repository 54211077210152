.menu-link {
  color: white;
  text-decoration: none; /* no underline */
}
.mobile-menu-link {
  color: rgba(0, 0, 0, 0.795);
  text-decoration: none; /* no underline */
}
.logo {
  max-width: 45px;
  margin-right: 1.5em;
}
