@font-face {
  font-family: TiltPrism;
  src: url(TiltPrism.ttf);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fancy-title {
  text-align: center;
  font-family: TiltPrism;
  font-size: 90px;
  color: rgb(128, 128, 128);
  animation: fadeIn 5s;
}
