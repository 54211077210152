.home {
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding: 50px 20px 50px 20px;

  .search-wrapper {
    margin-bottom: 30px;
    .search {
      text-align: center;

      .sources {
        .item {
          display: inline-block;
        }
      }

      .input {
        width: 100%;
        max-width: 400px;
        margin-top: 20px;
      }

      .progbar {
        margin-top: 10px;
      }
    }
  }

  .songs {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .home {
    .search-box {
      width: 100%;
    }
  }
}
