.songs .action-btn {
  background-color: rgba(106, 127, 184, 0.884);
  padding: 1px;
  color: white;
  height: 30px;
  width: 30px;
  margin-left: 5px;
}
.songs .action-btn:hover {
  background-color: rgb(106, 127, 184);
  color: rgb(206, 205, 205);
}
